import { graphql, Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '~/components/Layout';
import { TagPageQuery } from '~types/graphql-types';

interface TagTemplateProps {
  posts: Required<TagPageQuery['allMarkdownRemark']['edges']>;
  tag: string;
  title: string;
  totalCount: number;
}

/**
 * Template for a tag page
 */
function TagTemplate({ posts, tag, title, totalCount }: TagTemplateProps): JSX.Element {
  const postLinks = posts.map(post => (
    <li key={post.node!.fields!.slug!}>
      <Link to={post.node!.fields!.slug!}>
        <h2 className="is-size-2">{post.node!.frontmatter!.title}</h2>
      </Link>
    </li>
  ));
  const tagHeader = `${totalCount} publica${
    totalCount === 1 ? 'ción' : 'ciones'
  } etiquetadas con “${tag}”`;

  return (
    <Layout>
      <section className="section">
        <Helmet title={`${tag} | ${title}`} />
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1" style={{ marginBottom: '6rem' }}>
              <h3 className="title is-size-4 is-bold-light">{tagHeader}</h3>
              <ul className="taglist">{postLinks}</ul>
              <p>
                <Link to="/tags/">Todas las etiquetas</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

interface TagPageProps {
  data: TagPageQuery;
  pageContext: {
    tag: string;
  };
}

/**
 * Maps query params to template
 */
const TagPageContainer = ({ data, pageContext }: TagPageProps): JSX.Element => {
  const { allMarkdownRemark, site } = data;
  if (!allMarkdownRemark || !site || !site.siteMetadata || !site.siteMetadata.title) {
    throw new Error('Invalid site data');
  }

  return (
    <TagTemplate
      posts={allMarkdownRemark.edges}
      tag={pageContext.tag}
      title={site.siteMetadata.title}
      totalCount={allMarkdownRemark.totalCount}
    />
  );
};

export default TagPageContainer;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
